import { FC } from "react"
import { Card, CardContent } from "@mui/material"
import { TranscriptListItemDescription } from "./TranscriptListItemDescription"
import { TranscriptWithPerson } from "@interfaces/transcript"
import { FetchedMeeting } from "@src/api/meetings"

export const TranscriptListItem: FC<{
  transcript: TranscriptWithPerson
  searchTerm: string
  isSelected?: boolean
  meetingData: FetchedMeeting
}> = ({ transcript, searchTerm, isSelected = false, meetingData }) => {
  return (
    <Card
      key={`${searchTerm}-${transcript.id}`}
      sx={{
        border: isSelected ? 3 : 1,
        borderColor: "primary.dark",
        marginBottom: 1,
        position: "relative",
      }}
      variant="outlined"
    >
      <CardContent sx={{ paddingY: 0, "&:last-child": { paddingBottom: 0.5 } }}>
        <TranscriptListItemDescription
          transcript={transcript}
          searchTerm={searchTerm}
          isSelected={isSelected}
          meeting={meetingData}
        />
      </CardContent>
    </Card>
  )
}
