import { VoiceWithTranscript } from "@src/interfaces/voice"
import { FC, useEffect, useState } from "react"
import { useCloseModalKey, useData, useModalKey } from "./OpenModalHook"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material"
import { PersonVoicesTranscripts } from "@src/interfaces/person"
import Loading from "./Loading"
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRenderEditCellParams,
  GridRowsProp,
  useGridApiContext,
  GridValidRowModel,
} from "@mui/x-data-grid"
import { useUnverifyVoice, useVerifyVoice } from "@src/api/admin/voices"
import { isEqual } from "lodash"
import { useToast } from "./ToastHook"

const MeetingsComponent: FC<{ voice_id: number; meeting_ids: string[] }> = ({ voice_id, meeting_ids }) => {
  // Takes a list of meeting ids and returns a list of links to those meetings for data grid rendering
  if (!meeting_ids || meeting_ids.length === 0) {
    return <div>No meetings</div>
  }
  return (
    <Stack direction={"row"} spacing={1} overflow={"scroll"}>
      {meeting_ids.map((meeting_id, index) => (
        <Link key={index} href={`/meetings/${meeting_id}?voice-id=${voice_id}`} target="_blank" rel="noreferrer">
          {meeting_id}
        </Link>
      ))}
    </Stack>
  )
}

const SelectEditInputCell: FC<GridRenderEditCellParams> = ({ id, field, value, row }) => {
  const apiRef = useGridApiContext()

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value === "true" })
    apiRef.current.stopCellEditMode({ id, field })
  }

  return (
    <Box>
      <Select
        native
        value={value?.toString() || "false"}
        onChange={handleChange}
        autoFocus
        size="small"
        sx={{ height: 1 }}
        fullWidth
      >
        {/* Select inside datagrid doesn't like MenuItem for some reason */}
        <option value="true">Verified</option>
        <option value="false">Unverified</option>
      </Select>
    </Box>
  )
}

const renderSelectInputCell: GridColDef["renderCell"] = (params) => {
  return <SelectEditInputCell {...params} />
}

export const VOICE_MODAL_KEY = "VOICE_MODAL_KEY"

export const VoiceModal: FC = () => {
  const open = useModalKey(VOICE_MODAL_KEY)
  const closeModal = useCloseModalKey(VOICE_MODAL_KEY)
  const voiceModalData: PersonVoicesTranscripts = useData()

  const [rows, setRows] = useState<GridRowsProp>([])
  const [originalRows, setOriginalRows] = useState<GridRowsProp>([])
  const [changedRows, setChangedRows] = useState<{ [id: string]: GridRowModel }>({})
  const fieldsToCheck = ["verified"] // Fields to check for changes
  const toast = useToast()
  const { mutate: verifyVoice, isLoading: verifyIsLoading } = useVerifyVoice(() => {
    toast("Voice(s) verified")
  })
  const { mutate: unverifyVoice, isLoading: unVerifyIsLoading } = useUnverifyVoice(() => {
    toast("Voice(s) unverified")
  })

  const closeModalHelper = () => {
    setRows(originalRows)
    setChangedRows({})
    closeModal()
  }

  const getTitle = () => {
    if (!voiceModalData) {
      return "Loading..."
    }
    if (voiceModalData.name !== "") {
      return voiceModalData.name + "'s voice records"
    }
    return "Unknown name's voice records"
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "verified", headerName: "Verified", flex: 2, editable: true, renderEditCell: renderSelectInputCell },
    {
      field: "meeting_ids",
      headerName: "Meetings",
      flex: 2,
      renderCell: (params) => <MeetingsComponent voice_id={params.row.id} meeting_ids={params.value as string[]} />,
    },
  ]

  useEffect(() => {
    if (voiceModalData) {
      const initialRows =
        voiceModalData.voices?.map((voice: VoiceWithTranscript) => ({
          id: voice.id,
          verified: voice.verification_status,
          meeting_ids: voice.transcripts?.map((transcript) => transcript.meeting_id),
        })) || []
      setRows(initialRows)
      setOriginalRows(initialRows)
    }
  }, [JSON.stringify(voiceModalData)])

  const processRowUpdate = (newRow: GridValidRowModel) => {
    setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? newRow : row)))
    const originalRow = originalRows.find((r) => r.id === newRow.id)
    if (!isEqual(newRow, originalRow)) {
      // TODO - we should probably make this more generic for use across all datagrid implementations
      setChangedRows((prev) => ({ ...prev, [newRow.id]: newRow }))
    } else {
      const { [newRow.id]: _, ...rest } = changedRows
      setChangedRows(rest)
    }
    return newRow
  }

  const getUpdatedRows = () => {
    const updatedRows: Record<number, boolean> = {} //For now this will just store id and verified status
    fieldsToCheck.forEach((field) => {
      rows?.forEach((row) => {
        const originalRow = originalRows?.find((r) => r.id === row.id)
        if (originalRow && row[field] !== originalRow[field]) {
          updatedRows[row.id] = row[field]
        }
      })
    })
    return updatedRows
  }

  const handleUpdate = () => {
    const updatedRows = getUpdatedRows()
    Object.keys(updatedRows).forEach((id) => {
      if (updatedRows[parseInt(id)]) {
        verifyVoice({ id: parseInt(id) })
      } else {
        unverifyVoice({ id: parseInt(id) })
      }
    })
    setOriginalRows(rows)
    setChangedRows({})
  }

  return (
    <Dialog open={open} onClose={closeModalHelper} fullWidth maxWidth="md">
      {!voiceModalData ? (
        <DialogContent>
          <Loading useCloverleafIcon />
        </DialogContent>
      ) : (
        <>
          <DialogTitle>{getTitle()}</DialogTitle>
          <DialogContent>
            <DataGrid
              rows={rows || []}
              columns={columns}
              pagination
              loading={verifyIsLoading || unVerifyIsLoading}
              pageSizeOptions={[10, 25, 50, 100]}
              processRowUpdate={processRowUpdate}
              autoHeight
            />
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={closeModalHelper}>Close</Button>
        <Button onClick={handleUpdate} disabled={Object.keys(changedRows).length === 0}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
