import { FC } from "react"
import { Link as LinkComponent } from "@mui/material"
import { Link } from "react-router-dom"
import { Person } from "@interfaces/person"

export const PersonText: FC<{ person: Person }> = ({ person }) => {
  const getPersonRepresentation = () => {
    if (person.contact) {
      return `${person.contact.name}, ${person.contact.title}, ${person.contact.role}`
    }
    const nameList = [person.name]
    if (person.title && person.title.toLowerCase() !== "unknown") {
      nameList.push(person.title)
    }
    if (person.organization && person.organization.toLowerCase() !== "unknown") {
      nameList.push(person.organization)
    }
    return `${nameList.join(", ")}`
  }
  return (
    <>
      <LinkComponent component={Link} to={`/persons/${person.id}`}>
        {getPersonRepresentation()}
      </LinkComponent>
      <br />
    </>
  )
}
