import { FC, useContext, useEffect, ReactNode, useState } from "react"
import { Chip, Divider, Stack, Typography, Box } from "@mui/material"
import { TranscriptList } from "./TranscriptList"
import { TranscriptListItem } from "./TranscriptList/TranscriptListItem"
import { TranscriptWithPerson } from "@interfaces/transcript"
import { ClipShareContext } from "../../Promo/share/ClipShare"
import { useLocation, useParams, useHistory } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { generateParamsString } from "../../../functions/generateURL"
import { getWordTime } from "../../../functions/transcript-words-array"
import { PdfButton } from "./PdfButton"
import { BookmarkContext, Context } from "../Shared/Context"
import { unpackSetters } from "../../shared/unpackSetters"
import { useMeetingSearch } from "../../../api/search"
import { FetchedMeeting, useFetchMeetingTranscripts } from "../../../api/meetings"
import { useFetchMyClipSharesInBookmarks } from "../../../api/clip_shares"
import BookmarkEditor from "../Bookmark/BookmarkEditor"
import { ShareModal } from "../../shared/ShareModal"

const TranscriptListContainer: FC<{
  transcripts: [string, TranscriptWithPerson][]
  meetingData: FetchedMeeting
}> = ({ transcripts, meetingData }) => {
  return (
    <Stack>
      <TranscriptList transcripts={transcripts} meetingData={meetingData} />
    </Stack>
  )
}

export const TranscriptsTabContent: FC<{ meetingData: FetchedMeeting }> = ({ meetingData }) => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const searchString = useLocation().search
  const params = new URLSearchParams(searchString)

  const { state, setState } = useContext(Context)
  const { setVideoStartTime } = unpackSetters(state, setState)

  const terms = params.getAll("terms")
  const { data } = useMeetingSearch(params, meetingId)
  const refresh = params.get("refresh")
  const { data: transcriptsData } = useFetchMeetingTranscripts(meetingId)
  const history = useHistory()

  const { data: fetchMyClipsData, refetch } = useFetchMyClipSharesInBookmarks()
  const [selectedTranscriptId, setSelectedTranscriptId] = useState<string>()
  const [selectedComponent, setSelectedComponent] = useState<ReactNode>()

  const findSelectedTranscript = (
    transcripts: TranscriptWithPerson[],
    transcriptId: string,
    id_column: "id" | "extra_transcript_id",
  ) => {
    return transcripts.find((transcript) => {
      if (typeof transcript[id_column] === "number") {
        return transcript[id_column] === parseInt(transcriptId, 10)
      }
      return transcript[id_column] === transcriptId
    })
  }

  const obtainSelectedTranscript = (transcripts: TranscriptWithPerson[], transcriptId: string) => {
    const selectedTranscript = findSelectedTranscript(transcripts, transcriptId, "id")
    if (selectedTranscript) {
      return selectedTranscript
    }
    return findSelectedTranscript(transcripts, transcriptId, "extra_transcript_id")
  }

  useEffect(() => {
    // Refetch the data when the component mounts
    refetch()
  }, [])

  useEffect(() => {
    // Check if param transcript_id is null and return if it is
    const transcriptId = params.get("transcript_id")
    if (!transcriptId) {
      return
    }
    setSelectedTranscriptId(transcriptId)
  }, [params.get("transcript_id")])

  const [transcriptIdsToBookmarkIds, setTranscriptIdsToBookmarkIds] = useState<Record<string, number[]>>({})

  useEffect(() => {
    if (!fetchMyClipsData) {
      return
    }
    setTranscriptIdsToBookmarkIds(
      Object.values(fetchMyClipsData).reduce((acc, transcriptToBookmarkIds) => {
        Object.entries(transcriptToBookmarkIds).forEach(([transcriptId, bookmarkIds]) => {
          acc[transcriptId] = bookmarkIds
        })
        return acc
      }, {}),
    )
  }, [fetchMyClipsData, setTranscriptIdsToBookmarkIds])

  const activeTerm = params.get("active-term")
  useEffect(() => {
    if (refresh) {
      // Remove the refresh param from the URL
      const newParams = new URLSearchParams(params)
      newParams.delete("refresh")
      history.replace({ search: newParams.toString() })
    }
    if (selectedTranscriptId && transcriptsData) {
      const selectedTranscript = obtainSelectedTranscript(transcriptsData.transcripts, selectedTranscriptId)
      if (selectedTranscript) {
        // if there is an active-term we need to find the exact word start_time and minus 5 seconds from that
        if (activeTerm) {
          const wordTime = getWordTime(activeTerm, selectedTranscript)
          setVideoStartTime({ startTime: wordTime - 5, change: true })
        } else {
          setVideoStartTime({ startTime: selectedTranscript.start_time - 5, change: true })
        }
      }
    }
  }, [selectedTranscriptId, JSON.stringify(transcriptsData), refresh])

  useEffect(() => {
    const oldterms =
      decodeURIComponent(params.get("searchTerms") || "")
        ?.split(",")
        .filter((term) => term) || []
    if (oldterms > terms) {
      const newParams = new URLSearchParams(params)
      newParams.delete("terms")
      oldterms.forEach((term) => newParams.append("terms", term))
      history.replace({ search: generateParamsString({ terms: oldterms, prevParams: searchString }) })
    }
  }, [searchString])

  const termHits: { [index: string]: number } = Object.fromEntries(terms.map((term) => [term, 0]))
  let transcripts: [string, TranscriptWithPerson, number][] = []
  const noHitTerms: string[] = []

  const voiceId = params.get("voice-id")
  if (data) {
    data.terms.forEach((term_hit) => {
      const transcriptHits = term_hit.transcripts
      if (transcriptHits.length) {
        transcriptHits.forEach((transcript) => {
          const wordTime = getWordTime(term_hit.term, transcript)
          if (voiceId) {
            if (transcript.voice_id === parseInt(voiceId, 10)) {
              transcripts.push([term_hit.term, transcript, wordTime])
            }
          } else {
            transcripts.push([term_hit.term, transcript, wordTime])
          }
        })
        termHits[term_hit.term] = term_hit.hits
      } else {
        noHitTerms.push(term_hit.term)
      }
    })
    transcripts = transcripts.sort((a, b) => a[2] - b[2])
  } else if (voiceId && transcriptsData) {
    transcripts = transcriptsData?.transcripts
      .filter(({ voice_id }) => voice_id === parseInt(voiceId, 10))
      .map((transcript) => ["", transcript, transcript.start_time])
  }

  const transcriptsByPhraseComponent =
    !data && !transcripts.length ? (
      <></>
    ) : (
      <>
        {transcripts.length > 0 && (
          <TranscriptListContainer
            transcripts={transcripts.map(([term, transcript]) => [term, transcript])}
            meetingData={meetingData}
          />
        )}
      </>
    )

  const { description } = useContext(ClipShareContext)

  useEffect(() => {
    if (selectedTranscriptId && transcriptsData) {
      const selectedTranscript = obtainSelectedTranscript(transcriptsData.transcripts, selectedTranscriptId)
      if (selectedTranscript) {
        const term = params.get("active-term")
        if (term) {
          setSelectedComponent(
            <Box>
              <Divider sx={{ marginY: 1 }} />
              <TranscriptListItem
                searchTerm={term}
                transcript={selectedTranscript}
                isSelected
                meetingData={meetingData}
              />
              {description.trim().length !== 0 && (
                <Box fontWeight={40} border={1} padding={2}>
                  <Typography variant="h5" fontWeight="bold">
                    Notes
                  </Typography>
                  <Typography marginTop={1} fontWeight={500} variant="body2" whiteSpace="pre-wrap" color="black">
                    {description}
                  </Typography>
                </Box>
              )}
            </Box>,
          )
        }
      }
    }
  }, [selectedTranscriptId, JSON.stringify(transcriptsData)])

  return (
    <BookmarkContext.Provider value={{ transcriptIdsToBookmarkIds, setTranscriptIdsToBookmarkIds }}>
      <Stack>
        <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" rowGap={2}>
          {terms.length ? (
            <Chip color="primary" label={!data ? "loading..." : `Total matches: ${data.total_hits}`} />
          ) : null}
          <PdfButton />
        </Stack>
        {selectedComponent}
        <Divider sx={{ marginY: 1 }} />
        {transcriptsByPhraseComponent}
      </Stack>
      <BookmarkEditor />
      <ShareModal />
    </BookmarkContext.Provider>
  )
}
