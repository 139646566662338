import { FC } from "react"
import { Grid, List, useMediaQuery, useTheme } from "@mui/material"
import { TranscriptListItem } from "./TranscriptListItem"
import { TranscriptWithPerson } from "@interfaces/transcript"
import { FetchedMeeting } from "@src/api/meetings"

export const TranscriptList: FC<{
  transcripts: [string, TranscriptWithPerson][]
  meetingData: FetchedMeeting
}> = ({ transcripts, meetingData }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Grid container>
      <List
        sx={{
          overflow: "auto",
          maxHeight: smallScreen ? 320 : 580,
          marginBottom: 1,
          width: "100%",
        }}
      >
        {transcripts.map(([term, transcript]) => (
          <div key={`tli-${transcript.id}-${term}`}>
            <TranscriptListItem transcript={transcript} searchTerm={term} meetingData={meetingData} />
          </div>
        ))}
      </List>
    </Grid>
  )
}
