import { FC, useEffect, useState } from "react"
import { Voice, VoiceVerificationStatusMap, VoiceVerificationStatus } from "@interfaces/voice"
import { useFetchPersons } from "@api/admin/persons"
import { useUnverifyVoice, useVerifyVoice } from "@api/admin/voices"
import {
  Autocomplete,
  createFilterOptions,
  MenuItem,
  Select,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { PersonCells } from "./PersonCells"
import { useLocation, Link } from "react-router-dom"
import { Person } from "@src/interfaces/person"
import { useDebouncedCallback } from "use-debounce"
import { AdminContact } from "@src/interfaces/contact"

interface VoiceRowParams {
  voice: Voice & { speaker: string }
  personsMap?: Map<number, Person<AdminContact>>
}

export const VoiceRow: FC<VoiceRowParams> = ({
  voice: { id, speaker, person_id: origPersonId, verification_status },
  personsMap,
}) => {
  const [textValue, setTextValue] = useState("")
  const [debouncedTextValue, setDebouncedTextValue] = useState("")
  const [personId, setPersonId] = useState(origPersonId)

  const { mutate: verifyVoice, isLoading: verifyIsLoading } = useVerifyVoice()
  const { mutate: unverifyVoice, isLoading: unVerifyIsLoading } = useUnverifyVoice()

  const debouncedUpdateSearchTerm = useDebouncedCallback((value) => {
    setDebouncedTextValue(value)
  }, 500)

  useEffect(() => {
    debouncedUpdateSearchTerm(textValue)
  }, [textValue, debouncedUpdateSearchTerm])

  const { data: personData, isLoading: personDataLoading } = useFetchPersons(
    new URLSearchParams({ name: debouncedTextValue, count: "25" }),
    false,
    false,
    Boolean(debouncedTextValue && personId === null),
  )

  const [personsMapLocal, setPersonsMapLocal] = useState<Map<number, Person<AdminContact> | undefined>>(
    new Map(personsMap),
  )

  const newURLParams = new URLSearchParams(useLocation().search)
  newURLParams.set("voice-id", id.toString())

  useEffect(() => {
    setPersonId(origPersonId)
  }, [origPersonId])

  useEffect(() => {
    const newPersonsMap = new Map(personsMap)
    if (personData) {
      for (const person of personData.persons) {
        newPersonsMap.set(person.id, person)
      }
    }
    setPersonsMapLocal(newPersonsMap)
  }, [personsMap, personData])

  const getContactForPerson = (personId: number) => {
    if (personsMap) {
      return personsMap.get(personId)?.contact || null
    }
    return null
  }

  return (
    <TableRow>
      <TableCell>
        <Link
          to={{
            search: newURLParams.toString(),
          }}
        >
          {id}
        </Link>
      </TableCell>
      <TableCell>
        <Link
          to={{
            search: newURLParams.toString(),
          }}
        >
          {speaker}
        </Link>
      </TableCell>
      <TableCell>
        <Autocomplete
          inputValue={textValue}
          onInputChange={(_event, value) => setTextValue(value)}
          value={personId}
          onChange={(_event, value) => {
            setPersonId(value)
          }}
          loading={personDataLoading}
          renderInput={(params) => <TextField {...params} label="Person" size="small" />}
          options={personData?.persons.map((person) => person.id) || []}
          filterOptions={createFilterOptions({ limit: 25 })}
          placeholder="Search by name"
          loadingText="Loading..."
          noOptionsText={textValue.trim() != "" ? "No matching persons" : "Start typing to search"}
          getOptionLabel={(option) => {
            const person = personsMapLocal?.get(option)
            if (person) {
              return `${person.id}: ${person.name}${person.organization ? `, ${person.organization}` : ""}`
            }
            return ""
          }}
        />
      </TableCell>
      <TableCell>
        {personsMap && personId && getContactForPerson(personId) !== null ? (
          <Typography variant="body1">
            {getContactForPerson(personId)?.first_name} {getContactForPerson(personId)?.last_name}
          </Typography>
        ) : (
          <Typography variant="body1">No contact</Typography>
        )}
      </TableCell>
      <PersonCells
        voiceId={personId !== origPersonId ? id : null}
        personId={personId}
        personsMap={personsMap}
        contactData={personId ? getContactForPerson(personId) || undefined : undefined}
      />
      <TableCell align="center">
        {verifyIsLoading || unVerifyIsLoading ? (
          <Skeleton variant="rounded" width={24} height={24} sx={{ margin: "auto" }} />
        ) : (
          <Select
            value={verification_status}
            onChange={(event) => {
              if (
                event.target.value in [VoiceVerificationStatus.HumanVerified, VoiceVerificationStatus.MachineVerified]
              ) {
                verifyVoice({ id: id })
              }
              if (event.target.value === VoiceVerificationStatus.Unverified) {
                unverifyVoice({ id: id })
              }
            }}
            fullWidth
          >
            {Object.entries(VoiceVerificationStatusMap).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        )}
      </TableCell>
    </TableRow>
  )
}
