import { RubyObject } from "./default"
import { Transcript } from "./transcript"

export enum VoiceVerificationStatus {
  Unverified = "unverified",
  HumanVerified = "human_verified",
  MachineVerified = "llm_verified",
}

export const VoiceVerificationStatusMap = {
  [VoiceVerificationStatus.Unverified]: "Unverified",
  [VoiceVerificationStatus.HumanVerified]: "Human Verified",
  [VoiceVerificationStatus.MachineVerified]: "LLM Verified",
}

export interface Voice extends RubyObject {
  person_id: number | null
  verification_status: VoiceVerificationStatus
}

export interface VoiceWithTranscript extends Voice {
  transcripts?: Partial<Transcript>[]
}
