import { Button, Typography, Box, IconButton, Stack, Tooltip, BoxProps } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpWardIcon from "@mui/icons-material/ArrowUpward"
import Highlighter from "react-highlight-words"
import { FC, useContext, useEffect, useState } from "react"
import { useToast } from "../../../shared/ToastHook"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Transcript, TranscriptWithPerson } from "@interfaces/transcript"
import { centerTermInText } from "../../../shared/textTransformers"
import { IMeetingRouteParams } from "@api/interfaces"
import { FetchedMeeting, useFetchMeetingTranscripts } from "@api/meetings"
import { generateParamsString } from "@functions/generateURL"
import { TranscriptHighlighter } from "./TranscriptHighlighter"
import { getHighlighterRegexArray } from "@functions/highlighter"
import { PersonText } from "./PersonText"
import { TranscriptItemActionComponent } from "./TranscriptItemActions"
import { Context } from "../../Shared/Context"

const TEXT_PADDING = 64
const iconCss = { cursor: "pointer", verticalAlign: "middle" }

export interface Chunk {
  start: number
  end: number
}

interface TranscriptComponentProps {
  boxProps: BoxProps
  transcript: TranscriptWithPerson
  textToHighlight?: string
}

export interface FindChunks {
  autoEscape?: boolean | undefined
  caseSensitive?: boolean | undefined
  sanitize?: ((text: string) => string) | undefined
  searchWords: Array<string | RegExp>
  textToHighlight: string
}

export const TranscriptListItemDescription: FC<{
  transcript: TranscriptWithPerson
  searchTerm: string
  isSelected?: boolean
  meeting: FetchedMeeting
}> = ({ transcript, searchTerm, isSelected, meeting }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const setToast = useToast()
  const history = useHistory()
  const searchString = useLocation().search
  const urlParams = new URLSearchParams(searchString)
  const refresh = urlParams.get("refresh")
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data } = useFetchMeetingTranscripts(meetingId)
  const [currentTranscript, setCurrentTranscript] = useState<TranscriptWithPerson>(transcript)
  const [prevTranscript, setPrevTranscript] = useState<TranscriptWithPerson>()
  const [nextTranscript, setNextTranscript] = useState<TranscriptWithPerson>()
  const [atMinTranscripts, setAtMinTranscripts] = useState<boolean>(false)
  const [atMaxTranscripts, setAtMaxTranscripts] = useState<boolean>(false)

  const { state } = useContext(Context)

  useEffect(() => {
    setCurrentTranscript(transcript)
  }, [transcript])

  useEffect(() => {
    if (refresh) {
      setCurrentTranscript(transcript)
    }
  }, [refresh, transcript])

  const findNearbyTranscripts = (mainTranscript: Transcript, setPrev: boolean, setNext: boolean) => {
    if (!data) {
      return
    }
    const mainTranscriptIndex = data.transcripts.findIndex(
      (otherTranscript) => otherTranscript.id === mainTranscript.id,
    )
    if (mainTranscriptIndex === -1) {
      return
    }
    if (setPrev) {
      const startPrevIndex = Math.max(0, mainTranscriptIndex - 1)
      setAtMinTranscripts(startPrevIndex === 0)
      setPrevTranscript(data.transcripts[startPrevIndex] || undefined)
    }
    if (setNext) {
      const endNextIndex = Math.min(data.transcripts.length - 1, mainTranscriptIndex + 1)
      setAtMaxTranscripts(endNextIndex === data.transcripts.length - 1)
      setNextTranscript(data.transcripts[endNextIndex] || undefined)
    }
  }

  useEffect(() => {
    // Whenever data.transcripts changes, we need to update the prev and next transcripts
    // Or if transcript changes, we need to update all three
    if (isExpanded) {
      findNearbyTranscripts(transcript, true, true)
    }
  }, [data?.transcripts, isExpanded, transcript.id])

  const handleLoadPriorTranscript = (prevTranscript: TranscriptWithPerson) => {
    // This function shifts the current transcript to the previous transcript
    if (data) {
      setNextTranscript(currentTranscript)
      setCurrentTranscript(prevTranscript)
      findNearbyTranscripts(prevTranscript, true, false)
    }
  }

  const handleLoadNextTranscript = (nextTranscript: TranscriptWithPerson) => {
    // This function shifts the current transcript to the next transcript
    if (data) {
      setPrevTranscript(currentTranscript)
      setCurrentTranscript(nextTranscript)
      findNearbyTranscripts(nextTranscript, false, true)
    }
  }

  const { snippet, transcriptWords } = centerTermInText(
    currentTranscript.raw_text,
    TEXT_PADDING,
    [searchTerm],
    currentTranscript.words,
  )

  const handleCopyTextFromParagraph = () => {
    const cb = navigator.clipboard
    cb.writeText(
      isExpanded
        ? [prevTranscript?.raw_text, currentTranscript.raw_text, nextTranscript?.raw_text].join("\n")
        : snippet,
    ).then(() => setToast("Copied to your clipboard", 1750))
  }

  const TranscriptComponent = (props: TranscriptComponentProps) => (
    <Box
      onClick={() => {
        // Push history if current transcriptId param is different
        history.push({
          search: generateParamsString({
            transcriptId: props.transcript.id,
            activeTerm: searchTerm,
            prevParams: searchString,
            refresh: props.transcript.id.toString() === urlParams.get("transcript_id"),
          }),
        })
        document.getElementById("meeting-video-player-anchor-element")?.scrollIntoView()
      }}
      sx={{ cursor: "pointer", ...props.boxProps.sx }}
      {...props.boxProps}
    >
      <TranscriptItemActionComponent
        actionTranscript={props.transcript}
        searchTerm={searchTerm}
        meeting={meeting}
        handleCopyTextFromParagraph={handleCopyTextFromParagraph}
      />
      <Typography key={props.transcript.id}>
        {props.transcript.person && <PersonText person={props.transcript.person} />}
        <Highlighter
          key={props.transcript.id}
          textToHighlight={props.textToHighlight ?? props.transcript.raw_text}
          searchWords={getHighlighterRegexArray([searchTerm])}
        />
      </Typography>
    </Box>
  )

  return (
    <Box marginY={1}>
      {isSelected ? (
        isExpanded ? (
          <Box marginY={2}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title={state.playingVideo ? "Video must be paused" : ""}>
                <span>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      if (!prevTranscript) return
                      handleLoadPriorTranscript(prevTranscript)
                    }}
                    disabled={atMinTranscripts || !prevTranscript || state.playingVideo}
                  >
                    <ArrowUpWardIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
            <Box sx={{ maxHeight: "25vw", overflow: "scroll" }}>
              <TranscriptHighlighter
                transcriptWords={currentTranscript.words}
                transcript={currentTranscript}
                previousTranscript={prevTranscript}
                nextTranscript={nextTranscript}
                searchTerm={searchTerm}
                person={currentTranscript.person || undefined}
                meeting={meeting}
                handleCopyTextFromParagraph={handleCopyTextFromParagraph}
                isExpanded
                loadNextTranscript={handleLoadNextTranscript}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  if (!nextTranscript) return
                  handleLoadNextTranscript(nextTranscript)
                }}
                disabled={atMaxTranscripts || !nextTranscript}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box>
            <TranscriptHighlighter
              searchTerm={searchTerm}
              transcript={currentTranscript}
              transcriptWords={transcriptWords}
              person={currentTranscript.person || undefined}
              meeting={meeting}
              handleCopyTextFromParagraph={handleCopyTextFromParagraph}
            />
          </Box>
        )
      ) : isExpanded ? (
        <Stack spacing={2} marginY={2}>
          {prevTranscript && (
            <TranscriptComponent
              boxProps={{ border: 1, padding: 1, borderColor: "neutral.dark" }}
              transcript={prevTranscript}
              textToHighlight={prevTranscript.raw_text}
            />
          )}

          <TranscriptComponent
            boxProps={{ border: 1, padding: 1, borderColor: "neutral.dark" }}
            transcript={currentTranscript}
            textToHighlight={currentTranscript.raw_text}
          />

          {nextTranscript && (
            <TranscriptComponent
              boxProps={{ border: 1, padding: 1, borderColor: "neutral.dark" }}
              transcript={nextTranscript}
              textToHighlight={nextTranscript.raw_text}
            />
          )}
        </Stack>
      ) : (
        <TranscriptComponent
          boxProps={{ marginY: 2 }}
          transcript={currentTranscript}
          textToHighlight={`...${snippet}...`}
        />
      )}
      <Button variant="text" onClick={() => setIsExpanded((prevValue) => !prevValue)} size="small">
        {isExpanded ? "Less" : "More"}
        {isExpanded ? <ExpandLessIcon sx={iconCss} /> : <ExpandMoreIcon sx={iconCss} />}
      </Button>
    </Box>
  )
}
