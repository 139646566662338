import { FC, useContext } from "react"
import { BarChart, Bar, Brush, ReferenceLine, XAxis, CartesianGrid, Tooltip } from "recharts"
import { Skeleton, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material"
import { useFetchMeetingTranscripts } from "../../../api/meetings"
import { formatSeconds } from "../../shared/config"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { Context } from "../Shared/Context"
import { unpackSetters } from "../../shared/unpackSetters"

export const TimelineAccordion: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { state, setState } = useContext(Context)
  const { setVideoStartTime } = unpackSetters(state, setState)
  const history = useHistory()
  const params = new URLSearchParams(useLocation().search)
  const { data: transcriptsData, isLoading } = useFetchMeetingTranscripts(meetingId)
  if (isLoading) {
    return <Skeleton width={1000} height={150} variant="rectangular" />
  }

  const formattedData = transcriptsData?.transcripts.map((transcript) => {
    return { ...transcript, start_time_formatted: formatSeconds(transcript.start_time) }
  })

  const handleClick = (data: any) => {
    params.set("transcript_id", data?.id)
    history.push({ search: params.toString() })
    setVideoStartTime({ startTime: data.start_time, change: true })
  }

  return (
    <Accordion>
      <AccordionSummary>
        <Typography>Timeline</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <BarChart
          width={1000}
          height={150}
          data={formattedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="start_time_formatted" />
          <Tooltip />
          <ReferenceLine y={0} stroke="#000" />
          <Brush dataKey="start_time_formatted" height={30} stroke="#8884d8" />
          <Bar dataKey="summary_score" fill="#8884d8" onClick={handleClick} />
        </BarChart>
      </AccordionDetails>
    </Accordion>
  )
}
