import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { Contact } from "@interfaces/contact"

export const useFetchContacts = (organizationId: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    [`contacts_${organizationId}`],
    async () => {
      const response = await (await axiosInstance).get<Contact[]>(`/organizations/${organizationId}/contacts`)

      return response.data
    },
    {
      enabled: typeof organizationId === "number",
    },
  )
}
