import { FC, useEffect, useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material"
import { getMeetingVoices, useRunIdentifySpeakers } from "@api/admin/meetings"
import { VoiceRow } from "./VoiceRow"
import { useToast } from "@components/shared/ToastHook"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "@api/interfaces"
import { TableRowsLoader } from "@src/components/shared/Table"
import { useFetchPersonsMap } from "@src/api/admin/persons"

export const SpeakerIdentificationTable: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data: voiceData, isLoading: voiceDataLoading, refetch: refetchVoices } = getMeetingVoices(meetingId)
  const {
    data: personsMap,
    isLoading: personsMapLoading,
    refetch: refetchPersonsMap,
  } = useFetchPersonsMap(
    new URLSearchParams({ count: "-1", voice_ids: (voiceData?.map((voice) => voice.id.toString()) || []).join(",") }),
    false,
    true,
    Boolean(voiceData),
  )
  const { mutate: runIdentifySpeakers, isLoading: speakerIdentificationLoading } = useRunIdentifySpeakers(
    meetingId,
    () => {
      toast("Speaker identification Successful")
      refetchPersonsMap()
      refetchVoices()
    },
  )

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!voiceDataLoading && !personsMapLoading) {
      setIsLoading(false)
    }
  }, [voiceDataLoading, personsMapLoading])

  const toast = useToast()

  const handleSpeakerIdentification = () => {
    toast("Speaker identification started")
    runIdentifySpeakers()
  }

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Button variant="contained" onClick={handleSpeakerIdentification} disabled={speakerIdentificationLoading}>
            Run Speaker Identification
          </Button>
          {speakerIdentificationLoading && <CircularProgress />}
        </Stack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Voice_id</TableCell>
                <TableCell>Speaker</TableCell>
                <TableCell sx={{ minWidth: "36ch" }}>Person_id</TableCell>
                <TableCell sx={{ minWidth: "22ch" }}>Contact</TableCell>
                <TableCell sx={{ minWidth: "24ch" }}>Person Name</TableCell>
                <TableCell sx={{ minWidth: "24ch" }}>Person Title</TableCell>
                <TableCell sx={{ minWidth: "24ch" }}>Person Organization</TableCell>
                <TableCell>Update</TableCell>
                <TableCell>Verified</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading || speakerIdentificationLoading
                ? Array(10)
                    .fill(0)
                    .map((_, index) => (
                      <TableRow key={index}>
                        {Array(8)
                          .fill(0)
                          .map((_, index) => (
                            <TableRowsLoader />
                          ))}
                      </TableRow>
                    ))
                : voiceData?.map((voice) => <VoiceRow key={voice.id} voice={voice} personsMap={personsMap} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}
